<template>
    <moreCategories navType=2></moreCategories>
</template>
<script>
    import moreCategories from '@/components/moreCategories.vue';
    export default {
        components: {
            moreCategories
        },
    }
</script>
<style>

</style>